import {createRouter, createWebHistory} from "vue-router";
import Home from "@/views/Home"
import Requests from "@/views/Requests"
import Login from "@/views/Login";
import Cases from "@/views/Cases";
import CaseForm from "@/views/CaseForm";
import Services from "@/views/Services";
import ServiceForm from "@/views/ServiceForm";

const routes = [
    {
        path: "/",
        redirect: "/requests",
        component: Home
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/requests",
        component: Requests
    },
    {
        path: "/cases",
        component: Cases
    },
    {
        path: "/cases/create",
        component: CaseForm
    },
    {
        path: "/cases/edit/:id",
        component: CaseForm
    },
    {
        path: "/services",
        component: Services
    },
    {
        path: "/services/create",
        component: ServiceForm
    },
    {
        path: "/services/edit/:id",
        component: ServiceForm
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})
