<template>
  <div class="d-flex mb-4">
    <article class="">
      Запросы с сайта
    </article>
    <button class="btn btn-primary ms-auto" @click="fetchRequests">
      Обновить
    </button>
  </div>

  <div class="text-center" v-if="isRequestsLoading">
    Загружаемся...
  </div>

  <div class="text-danger text-center" v-else-if="requestsError">
    {{requestsError}}
  </div>

  <table class="table table-striped" v-else>
    <thead>
    <tr>
      <th scope="col">Дата и время</th>
      <th scope="col">Имя</th>
      <th scope="col">Телефон</th>
      <th scope="col">Почта</th>
      <th scope="col">Бриф</th>
      <th scope="col">Подробнее</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="req in requests">
      <td>{{ new Date(req.datetime).toLocaleString() }}</td>
      <td>{{ req.name }}</td>
      <td>{{ req.phone || '-' }}</td>
      <td>{{ req.email || '-' }}</td>
      <td>{{ req.briefAnswers.length ? '✔' : '-' }}</td>
      <td>
        <button @click="showRequest(req)" class="btn btn-sm btn-outline-primary">Открыть</button>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="window-backdrop" :class="isRequestWindowShow ? 'show' : ''"/>

  <div class="modal-window" :class="isRequestWindowShow ? 'show' : ''" v-if="activeRequest">
    <div class="d-flex">
      <span class="my-auto">
        <h6>Просмотр запроса</h6>
      </span>
      <div class="ms-auto my-auto text-end mb-3" style="cursor:pointer;" @click="isRequestWindowShow = false">
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.3125 2.5625L11.875 10L19.3125 17.4375L17.4375 19.3125L10 11.875L2.5625 19.3125L0.6875 17.4375L8.125 10L0.6875 2.5625L2.5625 0.6875L10 8.125L17.4375 0.6875L19.3125 2.5625Z"
              fill="black"/>
        </svg>
      </div>
    </div>
    <table class="table table-bordered mt-4">
      <tr>
        <td>Дата и время</td>
        <td>{{ new Date(activeRequest.datetime).toLocaleString() }}</td>
      </tr>
      <tr>
        <td>Имя</td>
        <td>{{activeRequest.name}}</td>
      </tr>
      <tr>
        <td>Телефон</td>
        <td>{{activeRequest.phone || '-'}}</td>
      </tr>
      <tr>
        <td>Почта</td>
        <td>{{activeRequest.email || '-'}}</td>
      </tr>
      <tr>
        <td>Ip</td>
        <td>{{activeRequest.ip}}</td>
      </tr>
      <tr>
        <td>Категория брифа</td>
        <td>{{activeRequest.briefMode}}</td>
      </tr>
    </table>

    <div class="mt-4" v-if="activeRequest.briefAnswers && activeRequest.briefAnswers.length">
      <h5>Ответы на вопросы брифа:</h5>
      <ul class="mt-2">
        <li v-for="answer in activeRequest.briefAnswers">{{answer}}</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {BACKEND_API} from "@/backend.config";

const isRequestsLoading = ref(true)
const requests = ref([])
const requestsError = ref("")
const activeRequest = ref(null)
const isRequestWindowShow = ref(false)

const fetchRequests = () => {
  axios.get(BACKEND_API + "/requests/get")
      .then((r) => {
        requests.value = r.data
      })
      .catch((e) => {
        requestsError.value = e
      })
      .finally(() => {
        isRequestsLoading.value = false
      })
}

const showRequest = (request) => {
  activeRequest.value = request
  isRequestWindowShow.value = true
}

onMounted(() => {
  fetchRequests()
})
</script>

<style scoped>
</style>
