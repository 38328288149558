<template>
  <h4>Категории услуг</h4>

  <div class="row mt-3">
    <div class="col-6 col-md-4 col-lg-3 mt-3">
      <div class="bg-primary create-btn text-white" @click="onClickCreateTopic">
        Создать топик
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div v-for="topic in topics" class="col-6 col-md-4 col-lg-3 mt-3">
      <TopicCard :name="topic.name" :visible="topic.visible" @click="onClickEditTopic(topic)"/>
    </div>
  </div>

  <div :class="isTopicWindowShow ? 'show' : ''" class="window-backdrop"></div>
  <div :class="isTopicWindowShow ? 'show' : ''" class="modal-window">
    <div class="d-flex">
      <span class="my-auto">
        <h6>{{ topicEditMode ? 'Редактирование' : 'Добавление' }} топика</h6>
      </span>
      <div class="ms-auto my-auto text-end mb-3" style="cursor:pointer;" @click="onCloseTopicsForm">
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.3125 2.5625L11.875 10L19.3125 17.4375L17.4375 19.3125L10 11.875L2.5625 19.3125L0.6875 17.4375L8.125 10L0.6875 2.5625L2.5625 0.6875L10 8.125L17.4375 0.6875L19.3125 2.5625Z"
              fill="black"/>
        </svg>
      </div>
    </div>

    <div class="mt-3">
      <label for="form_topic_name">Название</label>
      <input id="form_topic_name" v-model="editingTopic.name" class="form-control" type="text">

      <div class="form-check mt-2">
        <input id="form_topic_visible" v-model="editingTopic.visible" class="form-check-input" type="checkbox">
        <label class="form-check-label" for="form_topic_visible">Отображать топик</label>
      </div>

      <button :disabled="!editingTopic.name" class="btn btn-outline-primary mt-3 w-100" @click="onClickSubmitTopic">
        Сохранить
      </button>
    </div>
  </div>

  <hr>

  <h4>Услуги</h4>

  <div class="row mt-3">
    <div class="col-6 col-md-4 col-lg-3 mt-3">
      <router-link class="bg-primary d-block text-decoration-none create-btn text-white w-100" to="/services/create">
        Создать услугу
      </router-link>
    </div>
  </div>
  <div class="row mt-2">
    <router-link v-for="s in services" :to="'/services/edit/' + s._id" class="text-decoration-none mt-3 text-black col-6 col-md-4" tag="div">
      <ServiceCard :description="s.shortDescription" :icon="s.icon" :name="s.name"/>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import {BACKEND_API} from "@/backend.config";
import TopicCard from "@/components/TopicCard";
import ServiceCard from "@/components/ServiceCard";

export default {
  name: "Services",
  components: {ServiceCard, TopicCard},
  data() {
    return {
      isTopicWindowShow: false,
      topicEditMode: false,
      editMode: false,
      editingTopic: {
        name: "",
        visible: true
      },
      topics: [],
      services: []
    }
  },
  methods: {
    onClickCreateTopic() {
      this.editingTopic = {
        name: "",
        visible: true
      }
      this.editMode = false
      this.isTopicWindowShow = true
    },
    onClickEditTopic(t) {
      this.editingTopic = t
      this.isTopicWindowShow = true
      this.editMode = true
    },
    onCloseTopicsForm() {
      this.isTopicWindowShow = false
      this.loadTopics()
    },
    async onClickSubmitTopic() {
      if (this.editMode) {
        await axios.post(BACKEND_API + "/services/topics/edit/" + this.editingTopic._id, this.editingTopic)
      } else {
        await axios.post(BACKEND_API + "/services/topics/create", this.editingTopic)
      }

      await this.loadTopics()
      this.isTopicWindowShow = false
    },
    loadTopics() {
      axios.get(BACKEND_API + "/services/topics/get").then(r => {
        this.topics = r.data
      })
    },
    loadServices() {
      axios.get(BACKEND_API + "/services/getAllPrivate").then(r => {
        this.services = r.data
      })
    }
  },
  mounted() {
    this.loadTopics()
    this.loadServices()
  }
}
</script>

<style scoped>

</style>
