<template>
  <div @click="$emit('click')" class="case-topic-card" :class="{'not-visible': !visible}">
    {{name}}
  </div>
</template>

<script>
export default {
  name: "TopicCard",
  props: {
    id: {type: String},
    name: {type: String, required: true},
    visible: {type: Boolean, default: true}
  }
}
</script>

<style scoped>
.case-topic-card {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: 15px;
  text-align: center;
  background: white;
  transition: all .3s ease;
  cursor: pointer;
}

.case-topic-card.not-visible {
  opacity: .5;
}

.case-topic-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}
</style>
