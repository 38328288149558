<template>
  <div class="link w-100">
    <div class="pointer w-100 case-rounded-card__wrapper">
      <div class="case-rounded-card" :style="`background-image: url('${background}')`">
        <h5>{{name}}</h5>

        <p>{{description}}</p>

        <div class="mt-auto d-flex">
          <div class="cat-pill" v-for="cat in categories">{{cat}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseCard",
  props: {
    description: String,
    background: String,
    name: String,
    categories: Array[String]
  }
}
</script>

<style scoped>
.case-rounded-card__wrapper {
  height: 100%;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

.case-rounded-card {
  /*background-size: 100%;*/
  padding: 26px;
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: 25px;
  color: white;
  min-height: 100%;
  display: flex;
  background-position: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
}

.case-rounded-card h5 {
  font-weight: 600;
  font-size: 28px;
}

.case-rounded-card p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 100px;
}

@media screen and (max-width: 1200px) {
  .case-rounded-card h5 {
    font-size: 26px;
  }

  .case-rounded-card p {
    font-size: 18px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 998px) {
  .case-rounded-card h5 {
    font-size: 24px;
  }

  .case-rounded-card p {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.cat-pill {
  padding: 6px 16px;
  background: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30%;
  opacity: 0.8;
  color: black;
  border-radius: 100px;
  margin-right: 4px;
  font-size: 14px;
}
</style>
