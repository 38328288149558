<template>
  <section v-if="!caseLoading">
    <h4>{{ editMode ? "Редактирование" : "Добавление" }} кейса</h4>

    <div class="row pb-5">
      <div class="col-8">
        <div class="row mt-4">
          <div class="col-12 col-md-6 mt-2">
            <label for="case_name">Название кейса</label>
            <input id="case_name" v-model="caseItem.name"
                   class="form-control" type="text" @input="caseItem.slug = transliterate(caseItem.name.toLowerCase())">
          </div>
          <div class="col-12 col-md-6 mt-2">
            <label for="case_slug">Slug</label>
            <input id="case_slug" v-model="caseItem.slug" class="form-control" type="text">
          </div>
          <div class="col-12 col-md-6 mt-2">
            <label for="shortDescription">Краткое описание (на обложке)</label>
            <input id="shortDescription" v-model="caseItem.shortDescription" class="form-control" type="text">
          </div>
          <div class="col-12 col-md-6 mt-2">
            <label for="goal">Задача</label>
            <input id="goal" v-model="caseItem.goal" class="form-control" type="text">
          </div>
          <div class="col-12 col-md-6 mt-2">
            <label for="link">Ссылка на проект</label>
            <input id="link" v-model="caseItem.link" class="form-control" type="text">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mt-2">
            <label for="work_process">Процесс работы (markdown)</label>
            <textarea id="work_process" v-model="caseItem.work" class="form-control" rows="6"></textarea>
          </div>
          <div class="col-12 col-md-6 mt-2" v-html="marked(caseItem.work || '')">
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mt-2">
            <label for="work_process">Описание (markdown)</label>
            <textarea id="work_process" v-model="caseItem.description" class="form-control" rows="6"></textarea>
          </div>
          <div class="col-12 col-md-6 mt-2" v-html="marked(caseItem.description || '')">
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mt-2">
            <label for="work_process">Результаты работы (markdown)</label>
            <textarea id="work_process" v-model="caseItem.result" class="form-control" rows="6"></textarea>
          </div>
          <div class="col-12 col-md-6 mt-2" v-html="marked(caseItem.result || '')">
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="d-flex justify-content-between">
            <h5>Галерея кейса</h5>

            <div class="text-center">
              <input id="case_gallery_upload" accept="image/png,image/jpeg" multiple type="file"
                     @input="onCaseGalleryInput">
              <label :class="{'no-click': !caseItem.slug}"
                     class="ms-3 btn btn-outline-primary case_bg_upload-label cursor-pointer" for="case_gallery_upload">
                Загрузить картинки
              </label>
              <div v-if="!caseItem.slug" class="small text-danger">Перед загрузкой нужно указать название кейса!</div>
            </div>
          </div>

          <div class="row">
            <div v-for="g_img in caseItem.images" class="col-4 mt-2 position-relative">
              <a :href="g_img" target="_blank"><img :src="g_img" alt="" class="w-100"></a>
              <button class="btn btn-danger delete-img position-absolute bottom-0" @click="deleteGalleryImage">Удалить
              </button>
            </div>
          </div>
        </div>
        <hr>
        <div>
          <div class="d-flex justify-content-between">
            <h5>Похожие кейсы</h5>

            <button :disabled="!caseItem.topics.length" class="btn btn-outline-primary" @click="getSameCases">Подобрать
              похожие
            </button>
          </div>

          <div v-for="c in otherCases" class="mt-3">
            <div class="form-check">
              <input
                  :id="c._id"
                  :checked="caseItem.sameCases.includes(c._id)"
                  class="form-check-input"
                  type="checkbox"
                  @click="onSameCaseToggle(c)"
              >
              <label :for="c._id">{{ c.name }} ({{ c.shortDescription }})</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div>
          <h5 class="mt-3">Карточка кейса</h5>

          <CaseCard
              :background="caseItem.backgroundCover || noImg"
              :categories="wordTopics"
              :description="caseItem.shortDescription"
              :name="caseItem.name"
              class="mb-3"
          />

          <label for="case_bg">Обложка кейса</label>
          <input id="case_bg" v-model="caseItem.backgroundCover" class="form-control"
                 placeholder="Можно вставить ссылку"
                 type="text">

          <input id="case_bg_upload" accept="image/png,image/jpeg" type="file" @input="onCaseCoverInput">
          <label class="btn btn-outline-primary mt-2 case_bg_upload-label w-100 cursor-pointer" for="case_bg_upload">
            Загрузить обложку
          </label>
        </div>

        <hr>

        <div>
          <h5>Топики кейса</h5>

          <div v-for="t in topics" :key="t._id" :class="{'opacity-50': !t.visible}" class="form-check">
            <input :id="'topic_' + t._id" :checked="caseItem.topics.includes(t._id)" class="form-check-input"
                   type="checkbox" @input="onTopicToggle(t)">
            <label :for="'topic_' + t._id">{{ t.name }}</label>
          </div>
        </div>

        <div>
          <h5 class="mt-3">Видимость кейса</h5>

          <div class="form-check">
            <input id="visible" v-model="caseItem.visible" class="form-check-input" type="checkbox">
            <label for="visible">Отображать в общем списке</label>
          </div>

          <div class="form-check">
            <input id="favorite" v-model="caseItem.favorite" class="form-check-input" type="checkbox">
            <label for="favorite">Отображать списке на других страницах</label>
          </div>
        </div>

        <div>
          <h5 class="mt-3">Основная картинка</h5>

          <img :src="caseItem.mainImage || noImg" alt="" class="w-100 mb-3">
          <label for="case_main">Основная картинка</label>
          <input id="case_main" v-model="caseItem.mainImage" class="form-control" placeholder="Можно вставить ссылку"
                 type="text">

          <input id="case_main_pic_upload" accept="image/png,image/jpeg" type="file" @input="onCaseImageInput">
          <label class="btn btn-outline-primary mt-2 case_bg_upload-label w-100 cursor-pointer"
                 for="case_main_pic_upload">
            Загрузить картинку
          </label>
        </div>

        <hr>

        <div>
          <h4>Сохранение</h4>

          <div v-if="saveProblems.length">
            <h5>Проблемы кейса</h5>

            <ul>
              <li v-for="p in saveProblems" :class="{'fw-bold text-danger' : p[1]}" class="text-warning">{{ p[0] }}</li>
            </ul>
          </div>

          <button :class="isCriticalSaveProblems ? 'btn-danger' : saveProblems.length ? 'btn-warning' : 'btn-success'"
                  :disabled="isCriticalSaveProblems" class="btn w-100 mt-2"
                  @click="onSubmitCase">
            {{ isCriticalSaveProblems ? "Устарните критические проблемы!" : "Сохранить" }}
          </button>

          <div v-if="submitCaseError">
            Ошибка сохранения кейса: <code>{{ submitCaseError }}</code>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    Загружаемся...
  </div>
</template>

<script>
import {marked} from "marked"
import CaseCard from "@/components/CaseCard";
import noImg from "@/assets/no-img.png"
import axios from "axios";
import {BACKEND, BACKEND_API} from "@/backend.config";
import {transliterate} from "@/utils";

export default {
  name: "CaseForm",
  components: {CaseCard},
  data() {
    return {
      caseItem: {
        topics: [],
        visible: true,
        favorite: false,
        images: [],
        sameCases: []
      },
      noImg,
      topics: [],
      otherCases: [],
      submitCaseError: null,
      caseLoading: false
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params.id
    },
    wordTopics() {
      return this.topics.filter(t => this.caseItem.topics.includes(t._id) && t.visible).map(t => t.name)
    },
    saveProblems() {
      const problems = []

      if (!this.caseItem.name) {
        problems.push(["Не указано название кейса (крит)", true])
      }
      if (!this.caseItem.slug) {
        problems.push(["Не указан slug кейса (крит)", true])
      }
      if (!this.caseItem.shortDescription) {
        problems.push(["Не указано краткое описание кейса", false])
      }
      if (!this.caseItem.backgroundCover) {
        problems.push(["Не указана обложка кейса", false])
      }
      if (!this.caseItem.topics.length) {
        problems.push(["Не указаны топики кейса", false])
      }
      if (!this.caseItem.goal) {
        problems.push(["Не указана задача проекта", false])
      }
      if (!this.caseItem.mainImage) {
        problems.push(["Не указана основная картинка кейса", false])
      }
      if (!this.caseItem.images.length) {
        problems.push(["Не загружено ни одной картинки в галерею", false])
      }
      if (!this.caseItem.result) {
        problems.push(["Не указан результат проекта", false])
      }
      if (!this.caseItem.description && !this.caseItem.work) {
        problems.push(["Не указано описание или процесс работы", false])
      }

      if (!this.caseItem.visible && this.caseItem.favorite) {
        problems.push(["Кейс скрыт в основном блоке, но отображается в блоке на главной", false])
      }

      if (problems.length && (this.caseItem.visible || this.caseItem.favorite)) {
        problems.push(["Присутствуют проблемы! Уберите отображение кейса или пофиксите проблемы!", true])
      }

      if (this.caseItem.images.length < 3) {
        problems.push(["В галерею загружено меньше 3 картинок", false])
      }
      if (this.caseItem.topics.length > 3) {
        problems.push(["Указано больше 3 топиков кейса", false])
      }

      return problems
    },
    isCriticalSaveProblems() {
      return this.saveProblems.some(p => p[1])
    }
  },
  methods: {
    marked,
    transliterate,
    async uploadFile(file, fileName = "") {
      const data = new FormData()
      data.append("file", file)

      return BACKEND + "/" + (await axios.post(
          BACKEND_API + "/upload" + (fileName ? "?fileName=" + fileName : ""),
          data,
          {headers: {'Content-Type': 'multipart/form-data'}})).data.replace("\\", "/")
    },
    onCaseImageInput(e) {
      this.uploadFile(e.target.files[0], this.caseItem.slug ? this.caseItem.slug + "_main" : "")
          .then(r => this.caseItem.mainImage = r)
    },
    onCaseCoverInput(e) {
      this.uploadFile(e.target.files[0], this.caseItem.slug ? this.caseItem.slug + "_cover" : "")
          .then(r => this.caseItem.backgroundCover = r)
    },
    onCaseGalleryInput(e) {
      for (let f_ind = 0; f_ind < e.target.files.length; f_ind++) {
        this.uploadFile(e.target.files[f_ind], this.caseItem.slug + "_gallery_" + f_ind)
            .then(r => this.caseItem.images.push(r))
      }
    },
    deleteGalleryImage(img) {
      this.caseItem.images.splice(this.caseItem.images.indexOf(img), 1)
    },
    loadTopics() {
      return axios.get(BACKEND_API + "/cases/topics").then((r) => {
        this.topics = r.data
      })
    },
    loadCase(id) {
      return axios.get(BACKEND_API + "/cases/get/" + id).then(r => {
        if (!r.data) {
          this.$router.push("/cases")
        }
        this.caseItem = r.data
      })
    },
    loadOtherCases() {
      axios.get(BACKEND_API + "/cases/get?all=true").then(r => {
        this.otherCases = r.data.filter(c => c._id !== this.$route.params.id)
      })
    },
    onTopicToggle(topic) {
      if (this.caseItem.topics.includes(topic._id)) {
        this.caseItem.topics.splice(this.caseItem.topics.indexOf(topic._id), 1)
      } else {
        this.caseItem.topics.push(topic._id)
      }
    },
    onSameCaseToggle(c) {
      if (this.caseItem.sameCases.includes(c._id)) {
        this.caseItem.sameCases.splice(this.caseItem.sameCases.indexOf(c._id), 1)
      } else {
        this.caseItem.sameCases.push(c._id)
      }
    },
    getSameCases() {
      axios.get(BACKEND_API + "/cases/getSame/" + this.caseItem.topics.join(",")).then(r => {
        this.caseItem.sameCases = r.data.map(c => c._id).filter(c => c !== this.$route.params.id)
      })
    },
    onSubmitCase() {
      if (this.$route.params.id) {
        axios.post(BACKEND_API + "/cases/edit/" + this.$route.params.id, this.caseItem)
            .then(() => {
              this.$router.push("/cases")
            })
            .catch((e) => {
              this.submitCaseError = e.message
            })
      } else {
        axios.post(BACKEND_API + "/cases/create", this.caseItem)
            .then(() => {
              this.$router.push("/cases")
            })
            .catch((e) => {
              this.submitCaseError = e.message
            })
      }
    }
  },
  mounted() {
    this.loadTopics()
    this.loadOtherCases()

    if (this.$route.params.id) {
      this.caseLoading = true
      this.loadCase(this.$route.params.id).then(() => {
        this.caseLoading = false
      })
    }
  }
}
</script>

<style scoped>
#case_bg_upload, #case_main_pic_upload, #case_gallery_upload {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.delete-img {
  bottom: 0;
  width: 50%;
  left: calc((100% - 50%) / 2);
  opacity: .3;
  transition: all .3s ease;
}

.delete-img:hover {
  opacity: 1;
}

.no-click {
  pointer-events: none;
}
</style>
