<template>
  <section v-if="!loadingCasesTopics">
    <h4>Категории кейсов</h4>

<!--    region Cases topics-->
    <div class="row mt-3">
      <div class="col-6 col-md-4 col-lg-3 mt-3">
        <div class="bg-primary create-btn text-white" @click="onClickCreateTopic">
          Создать топик
        </div>
      </div>
    </div>
    <div class="row mt-2">

      <div v-for="t in topics" :key="t.id" class="col-6 col-md-4 col-lg-3 mt-3">
        <TopicCard :id="t._id" :name="t.name" :visible="t.visible" @click="showTopicEdit(t)"/>
      </div>
    </div>

    <div :class="isTopicEditWindowShow ? 'show' : ''" class="window-backdrop"></div>

    <div :class="isTopicEditWindowShow ? 'show' : ''" class="modal-window">
      <div class="d-flex">
        <span class="my-auto">
          <h6>{{ topicEditMode ? 'Редактирование' : 'Добавление' }} топика</h6>
        </span>
        <div class="ms-auto my-auto text-end mb-3" style="cursor:pointer;" @click="onCloseTopicsForm">
          <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.3125 2.5625L11.875 10L19.3125 17.4375L17.4375 19.3125L10 11.875L2.5625 19.3125L0.6875 17.4375L8.125 10L0.6875 2.5625L2.5625 0.6875L10 8.125L17.4375 0.6875L19.3125 2.5625Z"
                fill="black"/>
          </svg>
        </div>
      </div>

      <div class="mt-4">
        <label for="topic_name">Название топика</label>
        <input
            id="topic_name"
            v-model="editingTopic.name"
            :class="{'is-invalid': formTopicError}"
            autocomplete="off"
            class="form-control"
            name="topic_name"
            placeholder=""
            @input="() => editingTopic.slug = transliterate(editingTopic.name.toLowerCase())"
        />

        <label class="mt-2" for="topic_slug">Slug топика</label>
        <input
            id="topic_slug"
            v-model="editingTopic.slug"
            :class="{'is-invalid': formTopicError}"
            autocomplete="off"
            class="form-control"
            name="topic_slug"
            placeholder=""
        />

        <div class="form-check mt-2">
          <input id="topic_visible" v-model="editingTopic.visible" class="form-check-input" type="checkbox">
          <label for="topic_visible">
            Отображать топик
          </label>
        </div>

        <span v-if="formTopicError" class="text-danger small text-center my-1">
          {{ formTopicError }}
        </span>

        <button :disabled="!(editingTopic.name && editingTopic.slug)" class="btn btn-primary w-100 mt-3"
                @click="onTopicSubmit">Сохранить
        </button>
        <button v-if="topicEditMode" :disabled="editingTopic.deletable.length" @click="onClickDeleteTopic" class="btn-sm btn w-100 mt-2 btn-danger">
          {{ !editingTopic.deletable.length ? "Удалить топик" : "Топик связан с кейсами " + editingTopic.deletable.map(c => c.name).join(", ") }}
        </button>
      </div>
    </div>

    <hr>
<!--endregion-->

<!--    region Cases-->
    <h4 class="mt-5">Кейсы</h4>

    <div class="row mt-3">
      <div class="col-6 col-md-4 col-lg-3 mt-3">
        <router-link class="bg-primary text-decoration-none create-btn text-white d-block" to="/cases/create">
          Создать кейс
        </router-link>
      </div>
      <div class="col-3 my-auto">
        <div class="form-check">
          <input type="checkbox" id="hide-no-visible-cases" class="form-check-input" v-model="hideNoVisible"/>
          <label for="hide-no-visible-cases" class="form-check-label">Убрать скрытые</label>
        </div>
      </div>
    </div>

    <div>
      <button class="btn btn-warning btn-sm rounded-3 mt-2" @click="loadCasesProblems">
        Проверка кейсов
      </button>
    </div>

    <div class="cases-problems mt-2" v-if="problems.length > 0">
      <h6 class="text-warning">Проблемы кейсов:</h6>

      <ul>
        <li v-for="p in problems" :class="'text-' + p.status">{{p.text}}</li>
      </ul>
    </div>

    <div class="row mt-2">
      <router-link
          v-for="caseItem in filteredCases"
          :to="'/cases/edit/' + caseItem._id"
          :key="caseItem._id"
          :class="{'opacity-50': !caseItem.visible}"
          class="col-6 col-md-4 d-flex mt-3 text-decoration-none"
          tag="div"
      >
        <CaseCard
            :background="caseItem.backgroundCover || noImg"
            :categories="topics.filter(t => caseItem.topics.includes(t._id)).map(t => t.name)"
            :description="caseItem.shortDescription"
            :name="caseItem.name"
        />
      </router-link>
    </div>
  </section>
<!--  endregion-->
  <div v-else>
    Загружаемся...
  </div>
</template>

<script>
import axios from "axios";
import {BACKEND_API} from "@/backend.config";
import TopicCard from "@/components/TopicCard";
import CaseCard from "@/components/CaseCard";
import noImg from "@/assets/no-img.png"
import {transliterate} from "@/utils"

export default {
  name: "Cases",
  components: {CaseCard, TopicCard},
  data() {
    return {
      topics: [],
      cases: [],
      problems: [],
      hideNoVisible: false,
      noImg,
      editingTopic: {
        name: "",
        slug: "",
        visible: true
      },
      topicEditMode: false,
      isTopicEditWindowShow: false,
      formTopicError: null,
      loadingCasesTopics: false
    }
  },
  computed: {
    filteredCases() {
      if (this.hideNoVisible) {
        return this.cases.filter(c => c.visible)
      }
      return this.cases
    }
  },
  methods: {
    transliterate,
    showTopicEdit(t) {
      axios.get(BACKEND_API + "/cases/topics/checkDelete/" + t._id).then((r) => {
        this.editingTopic = {...t, deletable: r.data}
        this.isTopicEditWindowShow = true
        this.topicEditMode = true
      })
    },
    onClickCreateTopic() {
      this.editingTopic = {
        name: "",
        slug: "",
        visible: true
      }
      this.topicEditMode = false
      this.isTopicEditWindowShow = true
    },
    onClickDeleteTopic() {
      axios.delete(BACKEND_API + "/cases/topics/delete/" + this.editingTopic._id).then(() => {
        this.isTopicEditWindowShow = false
        this.loadTopics()
      })
    },
    async onTopicSubmit() {
      try {
        if (this.topicEditMode) {
          await axios.post(BACKEND_API + "/cases/topics/edit/" + this.editingTopic._id, this.editingTopic)
        } else {
          await axios.post(BACKEND_API + "/cases/topics/create", this.editingTopic)
        }
      } catch (e) {
        this.formTopicError = e.response.data.message
        return
      }

      await this.onCloseTopicsForm()
    },
    async onCloseTopicsForm() {
      await this.loadTopics()
      this.isTopicEditWindowShow = false
    },
    async loadTopics() {
      return axios.get(BACKEND_API + "/cases/topics").then((r) => {
        this.topics = r.data
      })
    },
    async loadCases() {
      return axios.get(BACKEND_API + "/cases/get?all=true").then((r) => {
        this.cases = r.data
      })
    },
    async loadCasesProblems() {
      //FIXME: Добавить лоадер проблем
      return axios.get(BACKEND_API + "/cases/problems").then((r) => {
        this.problems = r.data
      })
    }
  },
  async mounted() {
    await this.loadTopics()
    await this.loadCases()
    // await this.loadCasesProblems()

    this.loadingCasesTopics = false
  }
}
</script>

<style scoped>
</style>
