<template>
  <div v-if="!serviceLoading" class="row">
    <div class="col-8">
      <h4>{{ $route.params.id ? "Редактирование" : "Создание" }} услуги</h4>

      <div class="row">
        <div class="col-6">
          <label for="service_name">Название услуги</label>
          <input
              id="service_name"
              v-model="serviceItem.name"
              class="form-control"
              type="text"
              @input="() => serviceItem.slug = transliterate(serviceItem.name.toLowerCase())"
          >
        </div>
        <div class="col-6">
          <label for="service_name">Slug услуги</label>
          <input id="service_name" v-model="serviceItem.slug" class="form-control" type="text">
        </div>
        <div class="col-12">
          <label class="mt-2" for="service_name">Краткое описание</label>
          <input id="service_name" v-model="serviceItem.shortDescription" class="form-control" type="text">
        </div>
        <div class="col-6">
          <label class="mt-2" for="service_name">Иконка (<a
              href="https://fonts.google.com/icons?icon.set=Material+Icons">Google Icons</a>)</label>
          <input id="service_name" v-model="serviceItem.icon" class="form-control" type="text">
        </div>
        <div class="col-6">
          <label class="mt-2" for="service_slogan">Слоган</label>
          <input id="service_slogan" v-model="serviceItem.slogan" class="form-control" type="text">
        </div>
        <div class="col-6">
          <label class="mt-2" for="service_ex">Строка примеров</label>
          <input id="service_ex" v-model="serviceItem.exampleHeadline" class="form-control" type="text">
        </div>
      </div>

      <hr>

      <h5>Описание услуги (markdown)</h5>
      <div class="row">
        <div class="col">
          <textarea v-model="serviceItem.description" class="form-control" rows="10"></textarea>
        </div>
        <div class="col" v-html="marked(serviceItem.description || '')">
        </div>
      </div>

      <hr>

      <h5>Зачем вам «{{ serviceItem.name || "" }}»</h5>
      <div class="row">
        <div v-for="r in serviceItem.reasons" class="col-4">
          <input v-model="r.header" class="form-control" placeholder="Заголовок" type="text">
          <textarea v-model="r.content" class="form-control mt-2" placeholder="Пояснение" rows="8"></textarea>
        </div>
      </div>

      <hr>

      <h5>FAQ</h5>
      <div>
        <input id="" v-model="addFAQ.question" class="form-control" name="" placeholder="Вопрос" type="text">
        <textarea v-model="addFAQ.answer" class="form-control mt-2" placeholder="Ответ"></textarea>
        <button
            :disabled="!addFAQ.answer || !addFAQ.question"
            class="btn btn-outline-primary mt-3 w-100"
            @click="addToFAQList"
        >
          Добавить в FAQ
        </button>
      </div>

      <div v-for="(faq, index) in serviceItem.faqList" class="mt-4 d-flex justify-content-between">
        <ul>
          <li>Q: {{ faq.question }}</li>
          <li>A: {{ faq.answer }}</li>
        </ul>
        <button class="btn my-auto btn-danger" @click="onClickDeleteFaqItem(index)">Удалить</button>
      </div>

      <hr>

      <h5>Примеры кейсов</h5>
      <div v-for="c in cases" class="form-check">
        <input
            :id="'case_example_' + c._id"
            :checked="serviceItem.exampleCases.includes(c._id)"
            class="form-check-input"
            type="checkbox"
            @input="onExampleCaseToggle(c)"
        >
        <label :for="'case_example_' + c._id" class="form-check-label">{{ c.name }} ({{ c.shortDescription }})</label>
      </div>

      <hr>

    </div>
    <div class="col-4">
      <h5>Карточка услуги</h5>
      <ServiceCard class="h-auto" :description="serviceItem.shortDescription" :icon="serviceItem.icon" :name="serviceItem.name"/>

      <hr>

      <h5>Топики услуги</h5>
      <div v-for="t in topics" :class="{'opacity-50': !t.visible}" class="form-check">
        <input :id="'service_topics_' + t._id" :checked="serviceItem.topics.includes(t._id)" class="form-check-input"
               type="checkbox" @click="onTopicToggle(t._id)"/>
        <label :for="'service_topics_' + t._id" class="form-check-label">{{ t.name }}</label>
      </div>

      <hr>

      <h5>Видимость услуги</h5>
      <div class="form-check">
        <input id="visible" v-model="serviceItem.visible" class="form-check-input" type="checkbox">
        <label class="form-check-label" for="visible">Отображать в общем списке услуг</label>
      </div>
      <div class="form-check">
        <input id="favorite" v-model="serviceItem.favorite" class="form-check-input" type="checkbox">
        <label class="form-check-label" for="favorite">Отображать в списке на главной</label>
      </div>

      <hr>

      <h4>Сохранение</h4>
      <div v-if="saveProblems.length">
        <h5>Проблемы услуги</h5>

        <ul>
          <li v-for="p in saveProblems" :class="{'text-danger fw-bold': p[1]}" class="text-warning">{{ p[0] }}</li>
        </ul>
      </div>
      <button :class="{'btn-danger': isCriticalSaveProblems, 'btn-warning': saveProblems.length}"
              :disabled="isCriticalSaveProblems"
              class="btn btn-success w-100"
              @click="onSubmitService"
      >
        {{
          isCriticalSaveProblems ? "Устарните критические проблемы" : saveProblems.length ? "Есть проблемы, но можно сохранить" : "Сохранить"
        }}
      </button>
      <div v-if="saveServiceError" class="text-danger">
        При сохранении произошла ошибка: <code>{{ saveServiceError }}</code>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    Загружаемся...
  </div>
</template>

<script>
import {marked} from "marked";
import axios from "axios";
import {BACKEND_API} from "@/backend.config";
import {transliterate} from "@/utils"
import ServiceCard from "@/components/ServiceCard";

export default {
  name: "ServiceForm",
  components: {ServiceCard},
  data() {
    return {
      serviceItem: {
        exampleCases: [],
        reasons: [{}, {}, {}],
        faqList: [],
        topics: [],
        visible: true,
        favorite: true,
        slogan: "разработанный под Ваши нужды",
        exampleHeadline: "Примеры работ"
      },
      cases: [],
      topics: [],
      addFAQ: {
        question: "",
        answer: ""
      },
      saveServiceError: null,
      serviceLoading: false
    }
  },
  computed: {
    saveProblems() {
      const problems = []

      if (!this.serviceItem.name) {
        problems.push(["Не указано название (крит)", true])
      }
      if (!this.serviceItem.name) {
        problems.push(["Не указан slug (крит)", true])
      }
      if (!this.serviceItem.shortDescription) {
        problems.push(["Не указано краткое описание", false])
      }
      if (!this.serviceItem.shortDescription) {
        problems.push(["Не указано полное описание", false])
      }
      if (!this.serviceItem.icon) {
        problems.push(["Не указана иконка", false])
      }
      if (!this.serviceItem.reasons.every(r => r.header && r.content)) {
        problems.push(["Не указаны причины", false])
      }
      if (!this.serviceItem.faqList.length) {
        problems.push(["Не заполнен FAQ", false])
      }
      if (!this.serviceItem.exampleCases.length) {
        problems.push(["Не указаны примеры проектов", false])
      }
      if (!this.serviceItem.topics.length) {
        problems.push(["Не указаны топики услуги", false])
      }
      if (!this.serviceItem.visible && this.serviceItem.favorite) {
        problems.push(["Услуга скрыта на основной странице, но отображается в блоке на главной", false])
      }

      if (problems.length && (this.serviceItem.visible || this.serviceItem.favorite)) {
        problems.push(["Есть проблемы сохранения, устраните их или выключите отображение услуги!", true])
      }

      if (this.serviceItem.faqList.length < 2) {
        problems.push(["В FAQ меньше 2 вопросов ", false])
      }
      if (this.serviceItem.topics.length > 1) {
        problems.push(["Выбрано больше 1 топика ", false])
      }

      return problems
    },
    isCriticalSaveProblems() {
      return this.saveProblems.some(p => p[1])
    }
  },
  methods: {
    marked,
    transliterate,
    loadCases() {
      axios.get(BACKEND_API + "/cases/getAll").then(r => {
        this.cases = r.data
      })
    },
    loadTopics() {
      axios.get(BACKEND_API + "/services/topics/get").then(r => {
        this.topics = r.data
      })
    },
    onExampleCaseToggle(c) {
      if (this.serviceItem.exampleCases.includes(c._id)) {
        this.serviceItem.exampleCases.splice(this.serviceItem.exampleCases.indexOf(c._id), 1)
      } else {
        this.serviceItem.exampleCases.push(c._id)
      }
    },
    addToFAQList() {
      this.serviceItem.faqList.push(this.addFAQ)
      this.addFAQ = {
        question: "",
        answer: ""
      }
    },
    onClickDeleteFaqItem(i) {
      this.serviceItem.faqList.splice(i, 1)
    },
    onTopicToggle(tid) {
      if (this.serviceItem.topics.includes(tid)) {
        this.serviceItem.topics.splice(this.serviceItem.topics.indexOf(tid), 1)
      } else {
        this.serviceItem.topics.push(tid)
      }
    },
    async onSubmitService() {
      try {
        if (this.$route.params.id) {
          await axios.post(BACKEND_API + "/services/edit/" + this.serviceItem._id, this.serviceItem)
        } else {
          await axios.post(BACKEND_API + "/services/create", this.serviceItem)
        }

        this.$router.push("/services")
      } catch (e) {
        this.saveServiceError = e.message
      }
    },
    loadService() {
      return axios.get(BACKEND_API + "/services/get/" + this.$route.params.id)
          .then(r => {
            this.serviceItem = r.data
          })
          .catch(() => {
            this.$router.push("/services")
          })
    }
  },
  mounted() {
    this.loadCases()
    this.loadTopics()

    if (this.$route.params.id) {
      this.serviceLoading = true
      this.loadService().then(() => {
        this.serviceLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
