<template>
  <div class="container mt-5">
    <h1 class="text-center">NeutronAgency admin panel</h1>

    <div class="row">
      <div class="col-6 offset-3">
        <div class="mt-5">
          <label for="login">Логин</label>
          <input
              id="login"
              v-model="credentials.login"
              :class="loginError ? 'is-invalid' : ''"
              class="form-control"
              type="text"
              @input="loginError = false"
          >

          <label class="mt-2" for="password">Пароль</label>
          <input
              id="password"
              v-model="credentials.password"
              :class="loginError ? 'is-invalid' : ''"
              class="form-control"
              type="password"
              @input="loginError = false"
          >

          <button :class="loginError ? 'btn-danger' : ''" class="btn btn-primary mt-4 w-100" @click="submitLogin">
            Войти
          </button>
          <div v-if="loginError" class="mt-3 text-danger text-center">
            Не получилось войти: {{ loginError }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, ref} from "vue";
import axios from "axios"
import {BACKEND_API} from "@/backend.config";
import {useRouter} from "vue-router";

const router = useRouter()

const credentials = reactive({
  login: "",
  password: ""
})
const loginError = ref("")

const submitLogin = () => {
  axios.post(BACKEND_API + "/admin/login", credentials)
      .then(() => {
        router.push("/")
      })
      .catch((e) => {
        loginError.value = e.response.status + " " + e.response.statusText
      })
}
</script>

<style scoped>

</style>
