<template>
  <component :is="$route.path !== '/login' ? DefaultLayout : 'div'">
    <router-view/>
  </component>
</template>

<script setup>
import DefaultLayout from "@/layout/default"
import {onMounted} from "vue";
import {useRouter} from "vue-router";

const router = useRouter()

onMounted(() => {
  if (!document.cookie.includes("token")) {
    router.push("/login")
  }
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.window-backdrop {
  height: 0;
  transition: all .3s ease;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, .15);
  position: fixed;
}

.window-backdrop.show {
  height: 100vh;
}

.modal-window {
  position: fixed;
  top: -150%;
  padding: 20px;
  background: white;
  z-index: 3;
  width: 30%;
  transition: all .3s ease;
  left: 35%;
  border-radius: 20px;
}

.modal-window.show {
  top: 30%;
}

.cursor-pointer {
  cursor: pointer;
}

.create-btn {
  padding: 12px;
  border-radius: 15px;
  text-align: center;
  transition: all .3s ease;
  cursor: pointer;
}

.create-btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}
</style>
