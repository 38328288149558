<template>
  <div class="border-bottom mb-4">
    <div class="container py-4">
      <div class="d-flex">
        <router-link class="menu-link text-center me-5" to="/">
          <h3 class="m-0">NeutronAgency</h3>
          <h5 class="m-0">admin panel</h5>
        </router-link>
        <router-link class="menu-link d-flex my-auto me-3" to="/requests">
          Запросы
        </router-link>
        <router-link class="menu-link d-flex my-auto me-3" to="/cases">
          Портфолио
        </router-link>
        <router-link class="menu-link d-flex my-auto me-3 " to="/services">
          Услуги
        </router-link>
      </div>
    </div>
  </div>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
.menu-link {
  font-size: 24px;
  text-decoration: none;
  color: black;
  transition: all .3s ease;
}

.menu-link:hover {
  color: rgba(15, 45, 113, 0.64)
}

.menu-link.disabled {
  opacity: .35;
}
</style>
