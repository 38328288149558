<template>
  <div class="service-card-bg d-block w-100 h-100 position-relative overflow-hidden">
    <i class="material-icons-round">
      {{ icon }}
    </i>
    <div class="d-flex flex-column p-3 h-100 w-100">
      <h5 class="service-card-header pointer">
        {{ name }}
      </h5>
      <p class="service-card-description">
        {{ description }}
      </p>
      <div class="issue-price mb-1 mt-auto">
        <span class="service-card-price my-auto"><slot name="price"></slot></span>
        <div class="d-flex justify-content-end">
          <div class="my-auto">
            Узнать больше
          </div>

          <svg fill="none" height="24" viewBox="0 0 51 24" width="51" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50.5427 13.0607C51.1285 12.4749 51.1285 11.5251 50.5427 10.9393L40.9968 1.3934C40.411 0.807611 39.4613 0.807611 38.8755 1.3934C38.2897 1.97919 38.2897 2.92893 38.8755 3.51472L47.3608 12L38.8755 20.4853C38.2897 21.0711 38.2897 22.0208 38.8755 22.6066C39.4613 23.1924 40.411 23.1924 40.9968 22.6066L50.5427 13.0607ZM0.579834 13.5H49.4821V10.5H0.579834V13.5Z"
                fill="#7092E0"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    variant: {type: String, default: 'light'},
    to: {type: String, default: "/services"},
    icon: String,
    name: String,
    description: String
  }
}
</script>

<style scoped>
.service-card-bg {
  /*height: 100%;*/
  text-decoration: none;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 0.2s linear !important;
}
.service-card-bg:hover {
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
  color: black;
}

.issue-price {
  display: flex;
  justify-content: space-between;
}

.material-icons-round {
  position: absolute;
  font-size: 275px;
  line-height: 65px;
  top: 110px;
  right: 0;
  color: #8BAEF3;
  opacity: 0.18;
  user-select: none;
}
@media screen and (max-width: 998px) {
  .material-icons-round {
    font-size: 175px;
    top: 175px;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .material-icons-round {
    font-size: 150px;
    top: 75px;
  }
}

.service-card-header {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.1;
}

.service-card-description {
  line-height: 26px;
  margin-bottom: 60px;
}

.service-card-price {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
}

@media screen and (max-width: 998px) {
  .service-card-header {
    font-size: 32px;
  }

  .service-card-description {
    margin-bottom: 90px;
  }

  .service-card-price {
    font-size: 25px;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .service-card-header {
    font-size: 24px;
  }

  .service-card-description {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .service-card-price {
    font-size: 24px;
    line-height: 1;
  }
}
</style>
